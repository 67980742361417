<template>
  <div :class="{ answer__show: $store.state.postAnswer.value, answer__hidden: !this.$store.state.postAnswer.value }" class="form__container answer">
    <div :class="{ success: success, err: !success }" class="success">
      <p>{{ $store.state.postAnswer.value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostAnswer',
  computed: {
    success () {
      return this.$store.state.postAnswer.value && this.$store.state.postAnswer.success
    }
  }
}
</script>
