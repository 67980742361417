import Vue from 'vue'
import moment from 'moment'
import { VueMaskFilter } from 'v-mask'

Vue.filter('formatDate', (date) => {
  return moment(date).format('DD.MM.YYYY')
})
Vue.filter('humanDate', (date) => {
  return moment(date).format('DD MMMM YYYY')
})
Vue.filter('robotDate', (date) => {
  return moment(date)
})

Vue.filter('vMask', VueMaskFilter)
