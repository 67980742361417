import axios from 'axios'

async function POST_RECALL ({ state, commit }, data) {
  return await axios.post(state.api + '/api/recall/send', data)
}

async function POST_REMIND ({ state, commit }, data) {
  return await axios.post(state.api + '/api/reminder/send', data)
}

async function POST_CANCEL_SUBSCRIPTION ({ state, commit }, data) {
  return await axios.post(state.api + '/api/unsubscribe', data)
}

export {
  POST_RECALL,
  POST_REMIND,
  POST_CANCEL_SUBSCRIPTION
}
