export default {
  home_page (state, data) {
    state.home_page = data
    state.navigators = data.navigators
    state.contacts = data.contacts
    state.socials = data.socials
  },
  calc_page (state, data) {
    state.calc_page = data
    state.navigators = data.navigators
    state.contacts = data.contacts
    state.socials = data.socials
  },
  policy_page (state, data) {
    state.policy_page = data
    state.navigators = data.navigators
    state.contacts = data.contacts
    state.socials = data.socials
  },
  contacts_page (state, data) {
    state.contacts_page = data
    state.navigators = data.navigators
    state.contacts = data.contacts
    state.socials = data.socials
  },
  reports_page (state, data) {
    state.reports_page = data
    state.navigators = data.navigators
    state.contacts = data.contacts
    state.socials = data.socials
  }
}
