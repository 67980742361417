import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import * as filters from './filters.js'

import Meta from 'vue-meta'
import Cookie from 'vue-cookie'
import Seo from './plugins/seo.js'
import VueProgressBar from 'vue-progressbar'
import { VueMaskDirective } from 'v-mask'

import App from './App.vue'
import createStore from './store'
import createRouter from './router.js'
import VueJsonLD from 'vue-jsonld'
import VueMq from 'vue-mq'

Vue.use(VueProgressBar, {
  color: '#FF8000',
  failedColor: '#FF0000',
  thickness: '4px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})
Vue.use(VueJsonLD)
Vue.use(Meta)
Vue.use(Cookie)
Vue.use(Seo)
Vue.directive('mask', VueMaskDirective)
Vue.use(VueMq, {
  breakpoints: {
    768: 768,
    lg: Infinity
  },
  defaultBreakpoint: 'lg'
})

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

export default function createApp () {
  const store = createStore()
  const router = createRouter()
  sync(store, router)

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  return { app, router, store }
}
