<template>
  <div id="mavadda">
    <vue-progress-bar/>
    <HeaderComponent/>
    <Transition name="page" mode="out-in">
      <RouterView :key="$route.fullPath"/>
    </Transition>
    <div class="footer__container">
      <Cooperation/>
      <FooterComponent/>
    </div>
    <ModalCallMe/>
    <PostAnswer/>
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import Cooperation from './components/Cooperation.vue'
import ModalCallMe from './components/modals/CallMe.vue'
import PostAnswer from 'components/modals/PostAnswer.vue'

export default {
  components: {
    HeaderComponent,
    FooterComponent,
    Cooperation,
    ModalCallMe,
    PostAnswer
  },
  metaInfo () {
    return this.$seo()
  },
  created () {
    this.$Progress.start()
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })
    this.$router.afterEach(() => {
      this.$Progress.finish()
    })
  },
  mounted () {
    this.$Progress.finish()
  }
}
</script>

<style lang="stylus">
@import "styles/style.styl"
</style>
