import axios from 'axios'

/**
 * Получение данных главной страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_HOME_PAGE ({ state, commit }) {
  const { data } = await axios.get(state.api + '/api')
  commit('home_page', data)
}

async function POST_ORPHANS_DONATE ({ state, commit }, data) {
  return await axios.post(state.api + '/api/orphans', data)
}

async function TEST_DONATE ({ state, commit }, data) {
  return await axios.post(state.api + '/api/testpay', data)
}

export {
  GET_HOME_PAGE,
  POST_ORPHANS_DONATE,
  TEST_DONATE
}
