import axios from 'axios'

async function GET_CONTACTS_PAGE ({ state, commit }) {
  const { data } = await axios.get(state.api + '/api/contacts')
  commit('contacts_page', data)
}

export {
  GET_CONTACTS_PAGE
}
