<template>
  <div class="coop">
    <div class="coop__inner">
      <h3 class="coop__title">
        По вопросам сотрудничества
      </h3>
      <span class="coop__text">Оставьте заявку и наши специалисты ответят вам в течение часа.</span>
      <form @submit.prevent class="coop__form">
        <input v-model.trim="form.fio" :class="{ error: fioIsNotValid }" type="text" placeholder="Ваше имя" maxlength="255">
        <input v-model="form.phone" :class="{ error: phoneIsNotValid }" type="text"  v-mask="'+7 (###) ###-##-##'" placeholder="Ваш номер телефона" maxlength="255">
        <button type="button" @click="submitCoopForm">Оставить заявку</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cooperation',
  data () {
    return {
      form: {
        fio: '',
        phone: ''
      },
      formIsNotValid: false,
      fioIsNotValid: false,
      phoneIsNotValid: false
    }
  },
  methods: {
    submitCoopForm () {
      this.fioIsNotValid = !this.form.fio
      this.phoneIsNotValid = this.form.phone.length !== 18

      if (this.fioIsNotValid || this.phoneIsNotValid) {
        return 0
      }

      this.loading = true
      this.$store.dispatch('POST_COOP', {
        fio: this.form.fio,
        phone: this.form.phone
      })
        .then(response => {
          this.$store.state.postAnswer.value = 'Ваш запрос отправлен!'
          this.$store.state.postAnswer.success = true
          Object.keys(this.form).forEach(item => {
            this.form[item] = ''
          })
          this.loading = false
          setTimeout(() => {
            this.$store.state.postAnswer.value = ''
          }, 2000)
        })
        .catch(err => {
          this.$store.state.postAnswer.value = 'Произошла ошибка!'
          this.$store.state.postAnswer.success = false
          this.loading = false
          setTimeout(() => {
            this.$store.state.postAnswer.value = ''
          }, 2000)
          console.log(err)
        })
    }
  }
}
</script>
