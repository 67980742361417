<template>
  <header class="header">
    <div class="container">
      <a @click="showSideNav = true" class="header__menu">
        <HeaderMenu/>
      </a>
      <router-link :to="{ name: 'home' }" title="Главная страница" class="logo">
        <img src="public/images/logo.png" alt="">
        <LogoComponent/>
      </router-link>

      <nav class="header__nav" v-if="navigators && navigators.length">
        <ul class="header__nav__list">
          <li  class="header__nav__item" v-for="(item, index) in navigators" :key="index">
            <router-link :to="item.link" class="header__nav__link" >{{ item.title }}</router-link>
          </li>
        </ul>
      </nav>

      <div class="header__actions">
        <a v-if="contacts && contacts.number" :href="`tel:${contacts.number}`" class="header__actions__link" title="Телефон">
          <PhoneIcon/>
          <span>{{ contacts.number }}</span>
        </a>
      </div>
    </div>

<!--    боковое меню  -->
    <div :class="{ sidenav__show: showSideNav, sidenav__hidden: !showSideNav }" class="sidenav">
      <ul class="sidenav__links">
        <li @click="showSideNav = false" class="sidenav__close"><Close/></li>
        <li @click="showSideNav = false" class="sidenav__links__item" v-for="(item, index) in navigators" :key="index">
          <router-link :to="item.link" class="sidenav__link" >{{ item.title }}</router-link>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import LogoComponent from 'components/svg/Logo.vue'
import PhoneIcon from './svg/Phone.vue'
import HeaderMenu from './svg/HeaderMenu.vue'
import Close from 'components/svg/Close.vue'

export default {
  name: 'HeaderComponent',
  data () {
    return {
      showSideNav: false
    }
  },
  computed: {
    contacts: function () {
      return this.$store.state?.contacts
    },
    navigators: function () {
      return this.$store.state.navigators?.filter(item => item.title && item.link && item.type && item.type.code === this.$store.state.CODE_TOP)
    }
  },
  components: {
    LogoComponent,
    PhoneIcon,
    HeaderMenu,
    Close
  }
}
</script>
