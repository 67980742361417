<template>
  <i class="phone-icon icon">
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.5351 17.889C21.1291 17.651 20.6301 17.656 20.2261 17.896L18.1801 19.115C17.7221 19.388 17.1501 19.356 16.7301 19.028C16.0041 18.461 14.8351 17.507 13.8181 16.49C12.8011 15.473 11.8471 14.304 11.2801 13.578C10.9521 13.158 10.9201 12.586 11.1931 12.128L12.4121 10.082C12.6531 9.67798 12.6551 9.17498 12.4171 8.76898L9.41507 3.64098C9.12407 3.14498 8.54607 2.90098 7.98707 3.03798C7.44407 3.16998 6.73907 3.49198 6.00007 4.23198C3.68607 6.54598 2.45707 10.449 11.1591 19.151C19.8611 27.853 23.7631 26.625 26.0781 24.31C26.8191 23.569 27.1401 22.863 27.2731 22.319C27.4081 21.761 27.1681 21.187 26.6731 20.897C25.4371 20.174 22.7711 18.613 21.5351 17.889Z" fill="white"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'PhoneIcon'
}
</script>
