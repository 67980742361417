<template>
  <i class="close-icon">
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.72462 7.45871L14.7996 1.38371C14.9361 1.2243 15.0075 1.01923 14.9994 0.809503C14.9913 0.599773 14.9043 0.400823 14.7559 0.252411C14.6075 0.103999 14.4086 0.0170552 14.1988 0.00895422C13.9891 0.000853255 13.784 0.072192 13.6246 0.208714L7.54962 6.28371L1.47462 0.20038C1.3152 0.0638585 1.11014 -0.00747947 0.900409 0.000621488C0.690679 0.00872245 0.49173 0.0956657 0.343317 0.244078C0.194905 0.39249 0.107961 0.591439 0.0998603 0.801169C0.0917594 1.0109 0.163098 1.21596 0.29962 1.37538L6.37462 7.45871L0.291287 13.5337C0.204052 13.6084 0.133202 13.7004 0.0831822 13.8037C0.0331627 13.9071 0.00505389 14.0197 0.000620983 14.1345C-0.00381193 14.2493 0.0155264 14.3637 0.0574218 14.4706C0.0993173 14.5776 0.162866 14.6747 0.244078 14.7559C0.32529 14.8371 0.422413 14.9007 0.529351 14.9426C0.636288 14.9845 0.750732 15.0038 0.865498 14.9994C0.980264 14.9949 1.09287 14.9668 1.19626 14.9168C1.29965 14.8668 1.39158 14.7959 1.46629 14.7087L7.54962 8.63371L13.6246 14.7087C13.784 14.8452 13.9891 14.9166 14.1988 14.9085C14.4086 14.9004 14.6075 14.8134 14.7559 14.665C14.9043 14.5166 14.9913 14.3177 14.9994 14.1079C15.0075 13.8982 14.9361 13.6931 14.7996 13.5337L8.72462 7.45871Z" fill="#2A3742"></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'CloseIcon'
}
</script>
