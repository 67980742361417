import * as home from './home'
import * as calc from './calc'
import * as policy from './policy'
import * as contacts from './contacts'
import * as reports from './reports'
import * as feedback from './feedback'
import * as coop from './coop'
import * as modalActions from './modalActions'
import * as global from './global'

export default Object.assign(
  home,
  calc,
  policy,
  contacts,
  reports,
  feedback,
  coop,
  modalActions,
  global
)
