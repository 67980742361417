import axios from 'axios'

async function GET_REPORTS_PAGE ({ state, commit }) {
  const { data } = await axios.get(state.api + '/api/reports')
  commit('reports_page', data)
}

export {
  GET_REPORTS_PAGE
}
