import axios from 'axios'

async function GET_POLICY_PAGE ({ state, commit }) {
  const { data } = await axios.get(state.api + '/api/policy')
  commit('policy_page', data)
}

export {
  GET_POLICY_PAGE
}
