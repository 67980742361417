import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default function createRouter () {
  const routes = [{
    path: '/home',
    name: 'home',
    component: () => import('./views/home/index.vue')
  }, {
    path: '/zakyat',
    name: 'zakyat',
    component: () => import('./views/zakyat/index.vue')
  }, {
    path: '/policy',
    name: 'policy',
    component: () => import('./views/policy/index.vue')
  }, {
    path: '/contacts',
    name: 'contacts',
    component: () => import('./views/contacts/index.vue')
  }, {
    path: '/reports',
    name: 'reports',
    component: () => import('./views/reports/index.vue')
  }, {
    path: '/feedback',
    name: 'feedback',
    component: () => import('./views/feedback/index.vue')
  }, {
    path: '/confirmedpay',
    name: 'confirmedpay',
    component: () => import('./views/ConfirmedPay.vue')
  }, {
    path: '/testpay',
    name: 'testpay',
    component: () => import('./views/TestPay.vue')
  }, {
    path: '/confirmpay',
    name: 'confirmpay',
    props: true,
    component: () => import('./views/ConfirmPay.vue')
  }, {
    path: '*',
    name: 'notfound',
    component: () => import('./views/PageNotFound.vue')
  }]

  routes.forEach((item) => {
    const { meta } = item
    let { path } = item
    let baseDir = '/'
    if (meta && meta.project) {
      baseDir = `/${meta.project}`
    }

    if (path === '/home') {
      path = `${baseDir}/`
    } else if (path === '/' && item.redirect) {
      item.redirect = `${baseDir}/`
    } else {
      path = `${path}`
    }

    item.path = path
  })

  return new Router({
    mode: 'history',
    fallback: true,
    routes,
    scrollBehavior (to, from, savedPosition) {
      // window.scrollTo(0, 0, {
      //   behavior: 'smooth'
      // })
      if (to.hash) {
        return { selector: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    }
  })
}
