import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions/index'
import mutations from './mutations'

Vue.use(Vuex)
export default function createStore () {
  return new Vuex.Store({
    actions: actions,
    mutations: mutations,
    state () {
      return {
        api: 'https://mavadda.ru',
        navigators: [],
        contacts: {},
        socials: [],
        showRecallModal: false,
        showRemindModal: false,
        showVideoModal: false,
        showCancelSubscrModal: false,
        testDonateResponse: {},
        postAnswer: {
          success: false,
          value: ''
        },
        CODE_TOP: 1,
        CODE_BOTTOM: 2
      }
    }
  })
}
