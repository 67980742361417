<template>
  <footer class="footer">
    <div class="footer__one container" v-if="socials || contacts">
        <div class="footer__socials" v-if="socials && socials.length">
          <span class="socials__title">Мы в соцсетях</span>
          <ul class="socials__list">
            <li
              v-for="(item, index) in socials"
              :key="index"
            >
              <a :href="item.link" :title="item.title"  target="_blank" rel="noopener noreferrer"> <i v-html="item.icon"></i> </a>
            </li>
          </ul>
        </div>

        <div class="footer__actions">
          <span class="actions__phone" v-if="contacts.number">{{ contacts.number }}</span>
          <button @click="$store.state.showRecallModal = true" class="actions__call__btn">Перезвоните мне</button>
          <router-link :to="{ name: 'feedback' }" class="actions__feedback__btn">Обратная связь</router-link>
        </div>
      </div>

      <div class="footer__two" v-if="navigators && navigators.length">
        <div class="footer__two__inner">
          <ul class="footer__links">
            <li class="" v-for="(item, index) in navigators" :key="index">
              <router-link :to="item.link" >{{ item.title }}</router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer__three container">
        <router-link :to="{ name: 'home' }" class="logo footer__logo" title="Главная страница">
          <img src="public/images/logo.png" alt="">
          <LogoComponent/>
        </router-link>

        <span>© ООО Мавадда / 2021</span>
      </div>
  </footer>
</template>

<script>
import LogoComponent from 'components/svg/Logo.vue'

export default {
  name: 'FooterComponent',
  computed: {
    socials: function () {
      return this.$store.state?.socials
    },
    contacts: function () {
      return this.$store.state?.contacts
    },
    navigators: function () {
      return this.$store.state.navigators?.filter(item => item.title && item.link && item.type && item.type.code === this.$store.state.CODE_BOTTOM)
    }
  },
  components: {
    LogoComponent
  }
}
</script>
