<template>
  <svg width="144" height="38" viewBox="0 0 144 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M108.028 29.1218C108.028 29.3649 108.466 29.7699 108.887 29.9158C109.357 30.094 110.297 29.6241 110.297 29.2028C110.297 28.9112 110.232 28.8788 109.94 28.976C109.486 29.1218 108.887 29.138 108.401 29.0084C108.158 28.9436 108.028 28.9922 108.028 29.1218Z" fill="white"/>
    <path d="M119.095 29.851C119.046 30.0616 118.82 30.175 118.382 30.2398C117.086 30.4181 116.081 31.4551 115.806 32.9134C115.417 34.9875 116.502 36.7212 118.447 37.0777C118.884 37.1587 119.046 37.2722 119.046 37.499C119.046 37.7259 119.16 37.8069 119.533 37.8069C119.889 37.8069 120.019 37.7259 120.019 37.5152C120.019 37.3046 120.229 37.1912 120.829 37.0453C122.384 36.6726 123.227 35.7004 123.373 34.1125C123.47 32.9782 123.227 31.9898 122.66 31.3093C122.157 30.7259 121.137 30.2074 120.472 30.1912C120.132 30.1912 120.019 30.1102 120.019 29.8672C120.019 29.4297 119.208 29.4135 119.095 29.851ZM119.046 33.675C119.046 35.4088 118.982 36.3486 118.884 36.3486C118.512 36.3486 117.653 35.8787 117.296 35.4898C116.519 34.6472 116.502 32.7676 117.264 31.844C117.62 31.4227 118.35 31.0014 118.771 31.0014C119.014 31.0014 119.046 31.2445 119.046 33.675ZM121.282 31.3255C122.06 31.8116 122.449 32.5407 122.449 33.594C122.449 35.0523 121.736 36.0569 120.553 36.2838L120.019 36.381V33.6912C120.019 31.0014 120.019 31.0014 120.391 31.0014C120.586 31.0014 120.991 31.1472 121.282 31.3255Z" fill="white"/>
    <path d="M26.9292 30.3857C25.714 30.807 24.9038 32.1195 24.9038 33.675C24.9038 36.381 27.2371 37.9042 29.6676 36.7537C30.9153 36.1542 31.5797 34.4366 31.2232 32.7028C30.8181 30.7422 28.8899 29.7052 26.9292 30.3857ZM29.7001 31.6982C30.5912 32.6704 30.6074 34.7121 29.7325 35.6519C29.0033 36.4296 27.6422 36.5107 26.7672 35.8301C25.4547 34.7931 25.633 31.8764 27.0589 31.2283C27.3667 31.0825 27.9015 31.0177 28.3714 31.0663C29.0519 31.1149 29.2626 31.2121 29.7001 31.6982Z" fill="white"/>
    <path d="M46.2926 30.5478C45.7255 30.8394 45.45 31.1311 45.1097 31.8116C44.7046 32.5894 44.656 32.8487 44.7046 33.8371C44.8181 35.8787 45.6931 36.932 47.4916 37.1102C48.2532 37.1912 48.5125 37.1426 49.2416 36.7861C50.23 36.3 50.7323 35.6357 50.9916 34.5176C51.2995 33.0755 50.8134 31.5362 49.7763 30.7422C48.9338 30.1103 47.3134 30.0131 46.2926 30.5478ZM49.355 31.5524C50.6999 32.8487 50.2624 35.6033 48.6097 36.1704C47.4916 36.5593 46.2116 35.9922 45.7903 34.9227C45.5472 34.2908 45.6282 32.7515 45.9361 32.1357C46.5194 30.9529 48.399 30.6288 49.355 31.5524Z" fill="white"/>
    <path d="M126.079 30.3695C125.414 30.5639 124.458 31.6172 124.248 32.4111C123.826 33.9018 124.183 35.5222 125.123 36.3972C126.079 37.2722 127.942 37.3694 129.109 36.6078C130.243 35.8463 130.794 33.9991 130.34 32.4435C129.806 30.6287 128.072 29.7862 126.079 30.3695ZM128.38 31.2607C129.757 31.925 130 34.712 128.752 35.7653C127.65 36.6889 125.982 36.3972 125.382 35.1495C124.961 34.2907 124.944 33.0917 125.35 32.2005C125.836 31.1148 127.181 30.6936 128.38 31.2607Z" fill="white"/>
    <path d="M0.760742 33.675V36.9968H2.52693C3.49914 36.9968 4.48755 36.932 4.7144 36.8347C5.36254 36.5917 5.81624 35.7491 5.73522 34.9227C5.60559 33.6588 4.7306 33.1079 2.8672 33.1079H1.73295V32.1357V31.1635H3.27229C4.81162 31.1635 4.81162 31.1635 4.81162 30.7584V30.3533H2.78618H0.760742V33.675ZM4.2445 34.1125C4.98986 34.4528 4.98986 35.7491 4.2283 36.0246C4.00145 36.1218 3.35331 36.1866 2.76998 36.1866H1.73295V35.0523V33.9181H2.78618C3.35331 33.9181 4.01765 33.9991 4.2445 34.1125Z" fill="white"/>
    <path d="M7.90646 30.5802C7.1773 30.9853 7.09628 31.3903 7.08008 34.2746V36.9968H7.56618H8.05229V34.2422C8.05229 31.0339 7.97127 31.1635 9.89949 31.1635H11.131V34.0801V36.9968H11.6171H12.1032V33.675V30.3533H10.2074C8.92728 30.3695 8.16571 30.4343 7.90646 30.5802Z" fill="white"/>
    <path d="M14.4688 33.5778C13.7882 35.344 13.2373 36.8347 13.2373 36.8995C13.2373 36.9482 13.448 36.9968 13.691 36.9968C14.0961 36.9968 14.1771 36.8995 14.485 36.057L14.809 35.1334L16.1215 35.0847L17.4178 35.0361L17.7257 35.9759C18.0173 36.8509 18.0822 36.9157 18.5683 36.9644C18.8599 36.9968 19.0868 36.9482 19.0544 36.8833C19.022 36.8023 18.4872 35.2954 17.8553 33.5454L16.7211 30.3533H16.2188H15.7164L14.4688 33.5778ZM16.6238 32.6542C16.8345 33.3024 17.0451 33.9343 17.0775 34.0315C17.1424 34.1936 16.8993 34.2422 16.1539 34.2422C15.4086 34.2422 15.1655 34.1936 15.2303 34.0315C15.2627 33.9343 15.4734 33.3024 15.684 32.6542C15.8785 32.0223 16.1053 31.4876 16.1539 31.4876C16.2025 31.4876 16.4294 32.0223 16.6238 32.6542Z" fill="white"/>
    <path d="M20.2048 33.675V36.9968H20.6909H21.177V34.0801V31.1635H22.6354C24.0775 31.1635 24.0937 31.1635 24.0937 30.7584V30.3533H22.1493H20.2048V33.675Z" fill="white"/>
    <path d="M32.0983 30.7098C32.1469 31.0501 32.2441 31.0825 33.2163 31.1311L34.3019 31.1797V34.0801V36.9968H34.788H35.2742V34.0801V31.1635H36.3274C37.3482 31.1635 37.3806 31.1473 37.3806 30.7584V30.3533H34.707H32.0334L32.0983 30.7098Z" fill="white"/>
    <path d="M38.8389 33.675V36.9968H40.6699C42.5495 36.9968 42.9708 36.8833 43.4893 36.1866C44.0402 35.4736 43.7809 33.9829 43.0356 33.5778C42.7115 33.4158 42.6953 33.3672 42.9222 33.2051C43.6999 32.638 43.6837 31.439 42.8735 30.7746C42.4036 30.3857 42.274 30.3533 40.6051 30.3533H38.8389V33.675ZM42.3064 31.52C42.9708 32.476 42.3874 33.1079 40.8319 33.1079H39.8111V32.1357V31.1635H40.9291C41.9175 31.1635 42.0958 31.2121 42.3064 31.52ZM42.5819 34.388C43.0032 34.8903 42.9708 35.3116 42.4847 35.7815C42.1444 36.138 41.9499 36.1866 40.9453 36.1866H39.8111V35.0361V33.8857L41.0425 33.9505C42.0958 33.9991 42.3226 34.0639 42.5819 34.388Z" fill="white"/>
    <path d="M52.5471 30.7908C52.5633 31.0501 52.5796 32.5408 52.5958 34.1125L52.612 36.9968H53.017C53.4221 36.9968 53.4221 36.9806 53.4221 35.6195V34.2422H54.524C56.6628 34.2422 57.473 33.675 57.473 32.1519C57.473 31.5686 57.3758 31.3579 56.9221 30.9042L56.3712 30.3533H54.443H52.4985L52.5471 30.7908ZM56.1443 31.4228C56.5008 31.6658 56.6304 32.4922 56.3712 32.8973C56.1605 33.2213 55.3828 33.432 54.3781 33.432H53.4221V32.2977V31.1635H54.605C55.399 31.1635 55.9013 31.2445 56.1443 31.4228Z" fill="white"/>
    <path d="M59.0933 33.675V36.9968H59.628C60.1303 36.9968 60.1951 36.9157 61.6048 34.4042L63.0631 31.8278L63.1117 34.4042L63.1441 36.9968H63.6302H64.1164V33.675V30.3533H63.5816C63.0631 30.3533 63.0145 30.4343 61.6048 32.8649L60.1465 35.3926L60.0979 32.8649L60.0493 30.3533H59.5794H59.0933V33.675Z" fill="white"/>
    <path d="M64.959 30.7098C65.0076 31.0501 65.1048 31.0825 66.1095 31.1311L67.1951 31.1797V34.0801V36.9968H67.6812H68.1673V34.0801V31.1635H69.2205C70.2414 31.1635 70.2738 31.1473 70.2738 30.7584V30.3533H67.584H64.9104L64.959 30.7098Z" fill="white"/>
    <path d="M71.7322 33.675V36.9968H74.1627H76.5932V36.5917V36.1866H74.6488H72.7044V35.0523V33.9181H74.4868H76.2692V33.513V33.1079H74.4868H72.7044V32.1357V31.1635H74.5678H76.4312V30.7584V30.3533H74.0817H71.7322V33.675Z" fill="white"/>
    <path d="M79.0884 30.5154C78.3268 30.8232 78.2134 31.2769 78.2134 34.2422V36.9968H78.6023H79.0074L79.056 34.307C79.1208 31.0987 79.0722 31.1635 81.0004 31.1635H82.2643V34.0801V36.9968H82.7504H83.2365V33.675V30.3533L81.3407 30.3695C80.2874 30.3695 79.2828 30.4343 79.0884 30.5154Z" fill="white"/>
    <path d="M85.019 33.6912V37.0292L86.9797 36.9644L88.9403 36.9158L89.4102 36.381C89.7829 35.9759 89.8801 35.7005 89.8801 35.1496C89.8801 33.7885 88.9079 33.1079 86.9635 33.1079H85.8292V31.7306C85.8292 30.3695 85.8292 30.3533 85.4241 30.3533H85.019V33.6912ZM88.4218 34.1287C88.7621 34.307 88.8269 34.4528 88.8269 35.0523C88.8269 35.9597 88.4542 36.1866 86.9311 36.1866H85.8292V35.0523V33.9181H86.9311C87.5306 33.9181 88.1949 34.0153 88.4218 34.1287Z" fill="white"/>
    <path d="M91.5002 33.675V36.9967H91.9863H92.4725V35.4574V33.9019L94.0604 33.9505L95.6321 33.9991L95.6483 35.4574V36.9157L96.0858 36.9643L96.5233 37.0129V33.675V30.3371L96.102 30.3857C95.6808 30.4343 95.6645 30.4505 95.6483 31.7306L95.6321 33.0269L94.0604 33.0755L92.4725 33.1241V31.7306V30.3533H91.9863H91.5002V33.675Z" fill="white"/>
    <path d="M98.2087 30.5639C98.1763 30.6773 98.1601 32.1519 98.1763 33.837L98.2249 36.9157L100.007 36.9643C101.563 36.9967 101.854 36.9643 102.324 36.6726C103.621 35.8787 103.394 33.9504 101.935 33.3347C101.628 33.2051 100.882 33.1079 100.25 33.1079H99.1161V31.7306C99.1161 30.3695 99.1161 30.3533 98.711 30.3533C98.4841 30.3533 98.2573 30.4505 98.2087 30.5639ZM101.628 34.1125C102.373 34.4528 102.373 35.749 101.611 36.0245C101.385 36.1217 100.736 36.1865 100.153 36.1865H99.1161V35.0523V33.918H100.169C100.736 33.918 101.401 33.9991 101.628 34.1125Z" fill="white"/>
    <path d="M104.139 33.675V36.9968H104.544H104.949V33.675V30.3533H104.544H104.139V33.675Z" fill="white"/>
    <path d="M106.732 33.675V36.9968H107.283C107.817 36.9968 107.85 36.9482 109.259 34.4852C110.037 33.1079 110.718 31.9737 110.766 31.9737C110.815 31.9737 110.864 33.1079 110.864 34.4852V36.9968H111.317H111.755V33.675V30.3533H111.236C110.718 30.3695 110.653 30.4505 109.243 32.9297L107.785 35.506L107.736 32.9297L107.704 30.3533H107.218H106.732V33.675Z" fill="white"/>
    <path d="M132.009 33.675V36.9968H132.414C132.819 36.9968 132.819 36.9968 132.819 35.4574V33.9181H134.44H136.06V35.4574V36.9968H136.546H137.032V33.675V30.3533H136.546H136.06V31.7306V33.1079H134.44H132.819V31.7306C132.819 30.3695 132.819 30.3533 132.414 30.3533H132.009V33.675Z" fill="white"/>
    <path d="M139.22 30.7422L138.734 31.1311L138.685 33.6426C138.636 35.7977 138.588 36.1704 138.377 36.219C138.231 36.2514 138.102 36.4296 138.085 36.6241L138.053 36.9968H141.034H144V36.5917C144 36.3 143.919 36.1866 143.676 36.1866C143.368 36.1866 143.352 36.0732 143.352 33.27V30.3533H141.537C139.852 30.3533 139.673 30.3857 139.22 30.7422ZM142.541 33.4158C142.541 34.6635 142.493 35.7815 142.444 35.9273C142.363 36.138 142.071 36.1866 140.905 36.1866H139.463L139.511 33.9019C139.544 31.1635 139.56 31.1635 141.358 31.1635H142.541V33.4158Z" fill="white"/>
    <path d="M0.760742 11.4567V22.0206H2.16755H3.57437L3.62552 13.682L3.70226 5.31787L6.23452 13.682L8.76678 22.0461L10.1736 21.9694L11.5548 21.8927L14.0871 13.7587C15.4427 9.25694 16.6449 5.52249 16.7217 5.44576C16.7984 5.36902 16.8751 9.07789 16.8751 13.6564V22.0206H18.2819H19.6888V11.559C19.6888 1.55784 19.6632 1.07185 19.2028 0.918379C18.461 0.688173 16.1334 0.841644 15.7497 1.14858C15.5451 1.30206 14.2661 5.18997 12.8849 9.79409C11.5037 14.4238 10.3015 18.1838 10.2248 18.1838C10.0969 18.1838 7.25766 9.07789 5.18581 2.01825L4.82771 0.790487L2.78144 0.841644L0.760742 0.867222V11.4567Z" fill="white"/>
    <path d="M31.1992 0.943983C30.969 1.02072 29.3064 4.95979 26.8509 11.3288C24.6767 16.9816 22.8351 21.6881 22.7839 21.816C22.7583 21.9439 23.4234 22.0206 24.3186 21.9694L25.9301 21.8927L26.9532 18.9L28.0019 15.8818H32.1456H36.2637L37.2613 18.8744L38.2844 21.8927L39.8447 21.9694C40.7144 22.0206 41.4306 21.9694 41.4306 21.8671C41.4306 21.7648 40.3563 18.6954 39.0262 15.0121C37.6961 11.3544 36.0847 6.77586 35.3941 4.88306C34.729 2.99026 34.0128 1.2765 33.7826 1.09745C33.4245 0.816092 32.0433 0.713778 31.1992 0.943983ZM33.8849 8.7454C34.6523 11.0475 35.2918 13.017 35.2918 13.1193C35.2918 13.2216 33.9105 13.3239 32.2223 13.3239C30.5342 13.3239 29.1529 13.2728 29.1529 13.2216C29.1529 13.1193 31.6085 5.98293 32.0177 4.90864C32.12 4.57612 32.2991 4.34591 32.3758 4.42265C32.427 4.49938 33.1176 6.44334 33.8849 8.7454Z" fill="white"/>
    <path d="M45.5999 0.943964C45.3441 1.04628 45.2673 3.42507 45.2673 11.559V22.0206H50.5876C56.2405 22.0206 57.4171 21.8415 58.7727 20.8184C61.8677 18.4907 61.7142 13.2216 58.5425 11.2265L57.5961 10.6382L58.4914 9.89641C60.7167 8.05476 60.6144 4.62725 58.2867 2.40193C57.8008 1.9671 57.0846 1.45553 56.6497 1.30206C55.8312 0.995121 46.137 0.688181 45.5999 0.943964ZM55.9335 4.26916C57.3659 5.31787 57.3659 8.02918 55.9335 9.0779C55.2685 9.56389 54.8592 9.61504 51.7131 9.61504H48.2089L48.1321 6.59679L48.0554 3.55296L51.6364 3.65528C54.7825 3.73201 55.2941 3.78317 55.9335 4.26916ZM56.1893 12.8891C57.3148 13.4774 57.8775 14.4238 57.9031 15.7027C57.9542 18.6186 56.7265 19.3348 51.6619 19.3348H48.2089L48.1321 16.1631C48.1065 14.3982 48.1321 12.8123 48.2089 12.6077C48.4135 12.045 55.0639 12.2752 56.1893 12.8891Z" fill="white"/>
    <path d="M71.2551 1.17417C70.9738 1.40437 63.1724 21.3555 63.1724 21.8415C63.1724 21.9438 63.863 22.0206 64.6815 22.0206H66.1906L66.5231 21.0486C66.6766 20.537 67.1626 19.1558 67.5718 17.9792L68.3392 15.8818H72.4829H76.6266L77.6242 18.9512L78.6217 22.0206H80.2332H81.8446L81.0772 19.8976C80.6424 18.7465 78.9542 14.0145 77.2916 9.38484L74.2734 0.96954L72.9177 0.94396C72.176 0.918383 71.4342 1.0207 71.2551 1.17417ZM74.2478 9.02674L75.6546 13.3239H72.4829H69.3368L70.053 11.3288C70.4366 10.2545 71.1528 8.23381 71.6132 6.85257C72.0736 5.47134 72.5341 4.42262 72.6364 4.55052C72.7387 4.65283 73.4549 6.67352 74.2478 9.02674Z" fill="white"/>
    <path d="M90.797 1.0207C88.3159 1.30206 87.1137 2.09499 86.0395 4.11568C85.6046 4.90862 85.5535 5.7527 85.4767 12.1729L85.4256 19.3348L84.6582 19.4116C83.9164 19.4883 83.8909 19.5395 83.8909 20.7672V22.0206H93.227H102.563V20.7416V19.4627H101.54H100.517V10.1778V0.892805L96.501 0.867228C94.2757 0.867228 91.7179 0.918385 90.797 1.0207ZM97.7032 11.5334V19.4627H93.0991H88.495V12.6077C88.495 7.67108 88.5973 5.59923 88.8275 5.13882C89.4158 3.93664 90.5668 3.60412 94.3269 3.60412H97.7032V11.5334Z" fill="white"/>
    <path d="M111.771 1.04628C110.006 1.22533 109.213 1.53227 108.241 2.42751C106.835 3.68085 106.783 3.93664 106.655 11.9171L106.528 19.3348L105.709 19.4116C104.891 19.4883 104.865 19.5139 104.865 20.7672V22.0206H114.201H123.537V20.7416V19.4627H122.514H121.491V10.1266V0.790493L117.475 0.816072C115.25 0.84165 112.692 0.943964 111.771 1.04628ZM118.677 11.5334V19.4627H114.073H109.469V12.6077C109.469 7.67108 109.571 5.59923 109.802 5.13882C110.39 3.93664 111.541 3.60412 115.301 3.60412H118.677V11.5334Z" fill="white"/>
    <path d="M133.615 0.969561C133.462 1.07187 131.569 5.72714 129.42 11.2777C127.272 16.8282 125.481 21.5346 125.404 21.7136C125.302 21.9694 125.635 22.0206 126.862 21.9694L128.474 21.8927L129.522 18.9C130.111 17.1863 130.725 15.8562 130.929 15.8306C131.108 15.7795 132.976 15.7795 135.073 15.8306L138.859 15.9073L139.856 18.9L140.854 21.8927L142.44 21.9694C144 22.0462 144.025 22.0206 143.872 21.4834C143.309 19.7185 136.608 1.25092 136.48 1.12303C136.25 0.867247 133.922 0.764933 133.615 0.969561ZM137.861 13.0937C137.861 13.2216 136.48 13.3239 134.792 13.3239C133.103 13.3239 131.722 13.2216 131.722 13.1193C131.722 12.9147 133.948 6.52007 134.587 4.88306C134.817 4.32033 135.022 4.75516 136.352 8.56635C137.17 10.9196 137.861 12.9658 137.861 13.0937Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoComponent'
}
</script>
