import axios from 'axios'

async function GET_CALC_PAGE ({ state, commit }) {
  const { data } = await axios.get(state.api + '/api/calculate')
  commit('calc_page', data)
}

async function POST_ZAKYAT_PAY ({ state, commit }, data) {
  return await axios.post(state.api + '/api/zakyat', data)
}

export {
  GET_CALC_PAGE,
  POST_ZAKYAT_PAY
}
